import {Await} from '@remix-run/react'
import {Suspense} from 'react'

export interface BuildTimeSavedProps {
  buildTimeSaved: Promise<number>
}

export function BuildTimeSaved({buildTimeSaved}: BuildTimeSavedProps) {
  return (
    <Suspense fallback={<Fallback />}>
      <Await resolve={buildTimeSaved} errorElement={<Fallback />}>
        {(buildTimeSaved) => (
          <div className="text-radix-mauve11 text-lg">
            Over <span className="text-radix-mauve12 font-semibold">{buildTimeSaved.toLocaleString()} hours</span> saved
            last week.
          </div>
        )}
      </Await>
    </Suspense>
  )
}

function Fallback() {
  return (
    <div className="text-radix-mauve11 text-lg">
      Save <span className="text-radix-mauve12 font-semibold">hours</span> of build time.
    </div>
  )
}
