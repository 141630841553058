export function AppScreenshot() {
  return (
    <div className="group mt-16 overflow-hidden">
      <div className="relative mx-auto max-w-7xl">
        <div className="bg-radix-indigo7 absolute inset-0 inset-y-8 rounded-full opacity-60 blur-3xl transition-opacity duration-500 will-change-transform group-hover:opacity-90 group-hover:duration-500" />
        <div className="border-radix-mauve12/10 relative rounded-2xl border-4">
          <img
            src="/images/app-screenshot.webp"
            alt="App Screenshot"
            className="bg-radix-mauve-black w-full rounded-2xl"
            width="2560"
            height="1429"
          />
        </div>
      </div>

      <div className="from-radix-mauve-black/0 to-radix-mauve-black relative -mt-72 h-72 bg-linear-to-b"></div>
      <div className="bg-radix-mauve-black relative h-16"></div>
    </div>
  )
}
