import {cx} from 'class-variance-authority'
import {XLogo} from '~/components/logos'

export interface TweetEmbedProps {
  id: string
  name: string
  username: string
  children: React.ReactNode
  image: string
  date: string
  time: string
  className?: string
}

export function TweetEmbed(props: TweetEmbedProps) {
  return (
    <a
      className={cx(
        props.className,
        'border-radix-mauve6 bg-radix-mauve-black/50 hover:border-radix-blue7 relative block break-inside-avoid rounded-sm border p-4 transition duration-500',
      )}
      href={`https://x.com/${props.username}/status/${props.id}`}
      target="_blank"
      rel="noreferrer"
    >
      <XLogo className="text-radix-mauve12 absolute right-4 h-5 w-5" />
      <div>
        <div className="mb-3 flex items-center gap-3">
          <img
            src={props.image}
            alt={props.name}
            className="block h-12 w-12 rounded-full"
            decoding="async"
            loading="lazy"
            width="48"
            height="48"
          />
          <div>
            <div className="font-display leading-none font-bold">{props.name}</div>
            <div className="text-radix-mauve11 text-sm">@{props.username}</div>
          </div>
        </div>
        <div className="text-radix-mauve11 space-y-2">{props.children}</div>
      </div>
    </a>
  )
}
