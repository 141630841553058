import {cx} from 'class-variance-authority'
import {CheckCircleIcon, HomeIcon} from '~/components/icons'

export function GitHubActionsScreenshot({
  className,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      className={cx(
        'text-radix-mauve11 pointer-events-none relative inline-block w-full leading-none select-none',
        className,
      )}
      {...rest}
    >
      <div className="to-radix-mauve-black absolute inset-0 rounded-lg bg-linear-to-r from-transparent" />
      <div className="border-radix-mauve5 bg-radix-mauve2 rounded-lg border-y border-l">
        <div className="flex items-center justify-start px-4 py-6 text-base">
          <CheckCircleIcon className="text-radix-grass9 mr-1 inline-block h-5 w-5 align-middle" />
          <div className="mr-1 font-semibold">Add Depot build workflows</div>
          <div className="text-radix-mauve9">Depot CI #37</div>
        </div>

        <div className="flex">
          <div className="min-w-max px-4 pr-12">
            <div className="mt-4 text-xs">
              <HomeIcon className="mr-2 inline-block h-4 w-4 align-text-bottom" /> Summary
            </div>

            <div className="text-radix-mauve9 mt-6 text-xs font-semibold">Jobs</div>

            <div className="mt-4 text-xs">
              <CheckCircleIcon className="text-radix-grass9 mr-1 inline-block h-5 w-5 align-middle" /> Build with Depot
            </div>
          </div>
          <div className="border-radix-mauve6 bg-radix-mauve1 flex-1 rounded-br-lg border-t border-l">
            <div className="border-radix-mauve6 border-b p-4">
              <div className="text-sm font-semibold">Build with Depot</div>
              <div className="text-radix-mauve9 text-xs">succeeded 8 hours ago in 19s</div>
            </div>
            <div className="space-y-2 p-4 pr-8 pb-8 text-xs">
              <div className="">
                <CheckCircleIcon className="mr-1 inline-block h-5 w-5 align-middle" /> Set up job
              </div>
              <div className="">
                <CheckCircleIcon className="mr-1 inline-block h-5 w-5 align-middle" /> Run actions/checkout@v3
              </div>
              <div className="">
                <CheckCircleIcon className="mr-1 inline-block h-5 w-5 align-middle" /> Run depot/setup-action@v1
              </div>
              <div className="">
                <CheckCircleIcon className="mr-1 inline-block h-5 w-5 align-middle" /> Run depot build
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
