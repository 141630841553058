import type {MetaFunction} from '@remix-run/node'
import {defer} from '@remix-run/node'
import {Link, useLoaderData} from '@remix-run/react'
import {
  ArmColorLogo,
  AwsCdkColorLogo,
  AwsWhiteColorLogo,
  BitbucketColorLogo,
  BuildkiteColorLogo,
  CodeBuildColorLogo,
  CycleColorWhiteLogo,
  DockerColorLogo,
  FlyColorLogo,
  GitLabColorLogo,
  GoogleCloudPlatformColorLogo,
  IntelWhiteColorLogo,
  JenkinsColorLogo,
  TravisCIColorLogo,
} from '~/components/colorLogos'
import {BookIcon, ChevronRightIcon, CircleCILogoIcon, GitHubLogoIcon, GridOutlineIcon} from '~/components/icons'
import {BufLogo} from '~/components/logos'
import {calculateBuildTimeSaved} from '~/utils/marketing.server'
import {AppScreenshot} from './AppScreenshot'
import {BuildTimeChart} from './BuildTimeChart'
import {BuildTimeSaved} from './BuildTimeSaved'
import {GitHubActionsScreenshot} from './GitHubActionsScreenshot'
import {ProtobufCode} from './ProtobufCode'
import {TweetGrid} from './TweetGrid'
import {UsedByLogos} from './UsedByLogos'

interface Metric {
  id: number
  createdAt: string
  status: string
  conclusion: string
  dockerDuration: number
  depotDuration: number
  upstreamCommitMessage: string
}

interface LoaderData {
  metrics: {
    repo: string
    scrapeDate: string
    metrics: Metric[]
  }[]
}

export const meta: MetaFunction = () => {
  const depotDescription = 'Accelerated builds for Docker images and GitHub Actions workflows.'
  return [
    {title: 'Depot'},
    {name: 'description', content: depotDescription},
    {property: 'og:description', content: depotDescription},
    {property: 'og:image:alt', content: depotDescription},
    {property: 'twitter:description', content: depotDescription},
    {property: 'og:title', content: 'Depot'},
    {property: 'og:image', content: 'https://depot.dev/depot-social.png'},
    {property: 'twitter:title', content: 'Depot'},
    {property: 'twitter:image', content: 'https://depot.dev/depot-social.png'},
    {property: 'twitter:card', content: 'summary_large_image'},
  ]
}

const frontpageBenchmarks = [
  'benchmark-temporal',
  'benchmark-mastodon',
  'benchmark-posthog',
  'benchmark-dispatch',
  // 'benchmark-flagsmith',
  // 'benchmark-chatwoot',
]

export async function loader() {
  const res = await fetch('https://depot.github.io/scrape/results.json')
  const data: LoaderData['metrics'] = await res.json()
  const metrics = data.filter((m) => frontpageBenchmarks.includes(m.repo))
  return defer({metrics, buildTimeSaved: calculateBuildTimeSaved()})
}

const flags = `    --tag depot/app:latest
    --target app
    --file docker/Dockerfile
    --build-arg COMMIT=6fc3900f
    --platform linux/arm64,linux/amd64
    --progress plain
    --ssh default
    --push`

export default function Index() {
  const {metrics, buildTimeSaved} = useLoaderData<typeof loader>()
  //const useMessageVariant = useFeatureFlagVariantKey('landing-page-messaging-05-09-2024') === 'test'

  return (
    <div className="mx-4 -mt-16 flex flex-col pt-16 sm:mx-0">
      <div className="from-radix-mauve1 to-radix-mauve-black bg-linear-to-b">
        <div className="mx-auto w-full max-w-6xl pt-8 pb-8 sm:pt-24">
          <h1 className="font-display text-radix-mauve12 mb-6 text-4xl font-bold tracking-tight sm:text-6xl">
            Build faster. Waste less time.
          </h1>

          <div className="font-display text-radix-mauve11 mt-4 max-w-4xl text-lg sm:text-xl">
            Accelerate your <span className="font-medium whitespace-nowrap text-white">Docker image</span> builds and{' '}
            <span className="font-medium whitespace-nowrap text-white">GitHub Actions</span> workflows. Easily integrate
            <br />
            with your existing CI provider and dev workflows to save hours of build time.
          </div>

          <div className="font-display mt-8 flex items-center gap-2">
            <Link
              to="/start"
              className="border-radix-grass7 bg-radix-grass3 text-radix-grass11 hover:border-radix-grass8 flex items-center gap-2 rounded-lg border px-4 py-3 text-sm leading-none font-bold whitespace-nowrap"
            >
              <span>Start building</span>
              <ChevronRightIcon className="" />
            </Link>
            <Link
              to="/docs"
              className="border-radix-mauve7 bg-radix-mauve2 text-radix-mauve11 hover:border-radix-mauve8 rounded-lg border px-4 py-3 text-sm leading-none font-medium whitespace-nowrap"
            >
              Read documentation
            </Link>
          </div>
        </div>
      </div>

      <AppScreenshot />

      <div className="font-display text-radix-mauve12 my-8 flex flex-col items-center tracking-tight">
        <div className="text-xl font-semibold">Powering the world's best engineering teams</div>
        <div className="text-radix-mauve11 text-lg">From early-stage startups to public companies</div>
      </div>

      <UsedByLogos />

      <div className="border-radix-violet3 from-radix-violet1 to-radix-mauve-black mt-32 border-t bg-linear-to-b pt-32 pb-20">
        <div className="mx-auto flex w-full max-w-6xl flex-col items-center justify-between gap-4 text-center sm:flex-row sm:text-left">
          <div className="font-display flex-1 text-4xl font-bold tracking-tight whitespace-nowrap sm:text-5xl">
            The Depot Platform
          </div>
          <div className="text-radix-mauve11 flex-1 text-lg">
            Combining advanced software, powerful compute, and a globally-distributed cache to accelerate builds.
          </div>
        </div>
      </div>

      <div className="border-radix-mauve4 mx-auto flex w-full max-w-6xl flex-col border-y sm:flex-row">
        <div className="min-w-0 flex-1 space-y-4 py-16 sm:pr-12">
          <div className="font-display text-xl font-medium tracking-tight">Remote container builds</div>
          <div className="text-radix-mauve11 text-lg">
            Replace{' '}
            <code className="border-radix-mauve6 bg-radix-mauve2 text-radix-mauve12 -mx-px rounded-sm border px-px py-px whitespace-nowrap">
              docker build
            </code>{' '}
            with{' '}
            <code className="border-radix-mauve6 bg-radix-mauve2 text-radix-mauve12 -mx-px rounded-sm border px-px py-px whitespace-nowrap">
              depot build
            </code>
            . Native support for Intel and Arm images. Automatic layer caching. Use with your existing CI provider and
            dev workflows.
          </div>

          <div className="flex items-center gap-4 pb-4">
            <Link
              to="/products/container-builds"
              className="border-radix-grass7 bg-radix-grass3 text-radix-grass11 inline-flex items-center gap-1 rounded-lg border px-3 py-2 text-sm leading-none"
              prefetch="intent"
            >
              <div>See details</div>
              <ChevronRightIcon className="inline" />
            </Link>
            <Link
              to="/docs/container-builds/overview"
              className="text-radix-blue11 inline-flex items-center gap-1 text-sm"
              prefetch="intent"
            >
              <BookIcon className="h-4 w-4" />
              <div>Read the docs</div>
            </Link>
          </div>

          <div className="relative">
            <div className="to-radix-mauve-black absolute inset-0 top-16 bg-linear-to-b from-transparent" />
            <div className="border-radix-mauve5 bg-radix-mauve2 rounded-md border px-6 py-8 font-mono text-sm whitespace-pre">
              <div className="from-radix-red3 text-radix-red11 rounded-tl-md bg-linear-to-r to-transparent px-2">
                - docker build .
              </div>
              <div className="from-radix-grass3 text-radix-grass11 rounded-bl-md bg-linear-to-r to-transparent px-2">
                + depot build .
              </div>
              <div className="px-2">{flags}</div>
            </div>
          </div>
        </div>

        <div className="border-radix-mauve4 flex-1 space-y-4 border-t py-16 sm:border-t-0 sm:border-l sm:pl-12">
          <div className="font-display text-xl font-medium tracking-tight">GitHub Actions runners</div>
          <div className="text-radix-mauve11 text-lg">
            Hosted in AWS, with 30% faster CPUs, 10x faster networking and cache throughput, unrestricted concurrency,
            at half the cost of GitHub-hosted runners.
          </div>

          <div className="flex items-center gap-4 pb-4">
            <Link
              to="/products/github-actions"
              className="border-radix-grass7 bg-radix-grass3 text-radix-grass11 inline-flex items-center gap-1 rounded-lg border px-3 py-2 text-sm leading-none"
              prefetch="intent"
            >
              <div>See details</div>
              <ChevronRightIcon className="inline" />
            </Link>
            <Link
              to="/docs/github-actions/overview"
              className="text-radix-blue11 inline-flex items-center gap-1 text-sm"
              prefetch="intent"
            >
              <BookIcon className="h-4 w-4" />
              <div>Read the docs</div>
            </Link>
          </div>
          <GitHubActionsScreenshot />
        </div>
      </div>

      <div className="mx-auto flex w-full max-w-6xl flex-col pb-16 sm:flex-row">
        <div className="min-w-0 flex-1 space-y-4 py-16 sm:pr-12">
          <div className="font-display text-xl font-medium tracking-tight">Depot Cache</div>
          <div className="text-radix-mauve11 text-lg">
            Make all builds incremental with distributed remote caching for Bazel, Gradle, Turborepo, sccache, and
            Pants.
          </div>
          <div className="text-radix-mauve11 text-lg">
            Integrate with any CI provider and share cache with your entire team, even in local development.
          </div>

          <div className="flex items-center gap-4 pb-4">
            <Link
              to="/products/cache"
              className="border-radix-grass7 bg-radix-grass3 text-radix-grass11 inline-flex items-center gap-1 rounded-lg border px-3 py-2 text-sm leading-none"
              prefetch="intent"
            >
              <div>See details</div>
              <ChevronRightIcon className="inline" />
            </Link>
            <Link
              to="/docs/cache/overview"
              className="text-radix-blue11 inline-flex items-center gap-1 text-sm"
              prefetch="intent"
            >
              <BookIcon className="h-4 w-4" />
              <div>Read the docs</div>
            </Link>
          </div>

          <div className="mx-auto flex w-full max-w-6xl flex-col items-center justify-center gap-16 text-sm sm:flex-row">
            <div className="relative max-w-4xl flex-1">
              <div className="to-radix-mauve-black absolute inset-0 top-24 bg-linear-to-b from-transparent" />
              <div className="border-radix-mauve5 bg-radix-mauve2 rounded-md border px-6 py-8 font-mono whitespace-pre">
                <div className="from-radix-grass3 text-radix-grass11 rounded-bl-md bg-linear-to-r to-transparent px-2">
                  export SCCACHE_WEBDAV_ENDPOINT=https://cache.depot.dev
                </div>
                <div className="from-radix-grass3 text-radix-grass11 rounded-bl-md bg-linear-to-r to-transparent px-2">
                  export SCCACHE_WEBDAV_TOKEN=&lt;token&gt;
                </div>
                <div className="from-radix-grass3 text-radix-grass11 rounded-bl-md bg-linear-to-r to-transparent px-2">
                  export RUSTC_WRAPPER=sccache
                </div>
                <div className="mt-2 px-2">$ cargo build --release</div>
                <div className="text-radix-mauve11 px-2">{`   Compiling unicode-ident v1.0.13
   Compiling proc-macro2 v1.0.92
   Compiling libc v0.2.167
   Compiling cfg-if v1.0.0
   Compiling serde v1.0.215
   Compiling autocfg v1.1.0
   Compiling once_cell v1.20.2`}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-radix-mauve4 min-w-0 flex-1 space-y-4 border-t py-16 sm:border-t-0 sm:border-l sm:pl-12">
          <div className="font-display mb-4 text-xl font-medium tracking-tight">Build API for Platforms</div>
          <div className="text-radix-mauve11 max-w-lg text-lg">
            Programatically build container images on behalf of your customers in a secure, isolated environment.
          </div>
          <div className="text-radix-mauve11 max-w-lg text-lg">
            gRPC, Connect, and HTTP/JSON support, with a variety of language SDKs and integrations.
          </div>
          <div className="flex items-center gap-4 pt-2 pb-4">
            <Link
              to="/docs/container-builds/reference/api-overview"
              className="border-radix-grass7 bg-radix-grass3 text-radix-grass11 inline-flex items-center gap-1 rounded-lg border px-3 py-2 text-sm leading-none"
              prefetch="intent"
            >
              <div>Integration guide</div>
              <ChevronRightIcon className="inline" />
            </Link>
            <a
              href="https://buf.build/depot/api"
              className="text-radix-blue11 inline-flex items-center gap-1 text-sm"
              target="_blank"
              rel="noreferrer"
            >
              <BufLogo className="h-4 w-4" />
              <div>API reference</div>
            </a>
          </div>

          <ProtobufCode />
        </div>
      </div>

      <div className="border-radix-mauve4 mt-16 space-y-8 border-t py-32">
        <div
          id="benchmarks"
          className="border-radix-mauve5 mx-auto flex w-full max-w-6xl flex-col items-start justify-between gap-8 border-b pb-8 sm:flex-row sm:items-end"
        >
          <div className="space-y-2">
            <div className="flex items-center gap-4 pl-2">
              <div className="relative flex items-center justify-center">
                <div className="bg-radix-grass9 absolute h-2 w-2 rounded-full"></div>
                <div className="animate-pulse-slow bg-radix-grass9/60 absolute h-4 w-4 rounded-full"></div>
              </div>
              <div className="text-radix-mauve11 text-lg">Live Benchmarks</div>
            </div>
            <div className="font-display text-5xl font-bold tracking-tight">Unrivaled Performance</div>
          </div>

          <BuildTimeSaved buildTimeSaved={buildTimeSaved} />
        </div>
        <div className="mx-auto grid w-full max-w-6xl grid-cols-1 gap-12 sm:grid-cols-2">
          {[
            metrics.find((m) => m.repo === 'benchmark-temporal'),
            metrics.find((m) => m.repo === 'benchmark-mastodon'),
            metrics.find((m) => m.repo === 'benchmark-posthog'),
            metrics.find((m) => m.repo === 'benchmark-dispatch'),
            // metrics.find((m) => m.repo === 'benchmark-flagsmith'),
            // metrics.find((m) => m.repo === 'benchmark-chatwoot'),
          ].map((m) => {
            if (m) {
              return (
                <div key={m?.repo} id={`benchmark-${m?.repo}`}>
                  <BuildTimeChart metrics={m.metrics} repo={m.repo} />
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      </div>

      <div className="border-radix-mauve5 from-radix-mauve2 to-radix-mauve-black my-8 border-y bg-linear-to-tr py-32">
        <div className="mx-auto flex max-w-6xl flex-col items-center gap-16 text-center sm:text-left md:flex-row md:items-center">
          <div className="flex flex-1 flex-col gap-4">
            <div className="font-display text-4xl font-bold">Integrations</div>
            <div className="text-radix-mauve11 max-w-2xl text-xl">
              Use Depot with your favorite tools and services, from your existing{' '}
              <span className="text-radix-mauve12 whitespace-nowrap">CI providers</span>, to{' '}
              <span className="text-radix-mauve12 whitespace-nowrap">infrastructure-as-code</span>, to{' '}
              <span className="text-radix-mauve12 whitespace-nowrap">compute platforms</span>.
            </div>
            <div>
              <Link to="/integrations" className="text-radix-blue11 inline-flex items-center gap-2 text-lg">
                <GridOutlineIcon />
                <span>Browse the directory</span>
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex justify-end gap-4"></div>
            <div className="flex justify-end gap-4">
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <GitHubLogoIcon className="h-8 w-8" />
              </div>
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <CircleCILogoIcon className="h-8 w-8" />
              </div>
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <GitLabColorLogo className="h-8 w-8" />
              </div>
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <GoogleCloudPlatformColorLogo className="h-8 w-8" />
              </div>
            </div>
            <div className="flex justify-end gap-4">
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <BitbucketColorLogo className="h-8 w-8" />
              </div>
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <TravisCIColorLogo className="h-8 w-8" />
              </div>
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <BuildkiteColorLogo className="h-8 w-8" />
              </div>
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <JenkinsColorLogo className="h-8 w-8" />
              </div>
            </div>
            <div className="flex justify-end gap-4">
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <CodeBuildColorLogo className="h-8 w-8" />
              </div>
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <DockerColorLogo className="h-8 w-8" />
              </div>
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <AwsCdkColorLogo className="h-8 w-8" />
              </div>
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <CycleColorWhiteLogo className="text-radix-mauve11 h-8 w-8" />
              </div>
            </div>
            <div className="flex justify-end gap-4">
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <AwsWhiteColorLogo className="h-8 w-8" />
              </div>
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <FlyColorLogo className="h-8 w-8" />
              </div>

              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <IntelWhiteColorLogo className="h-8 w-8" />
              </div>
              <div className="border-radix-mauve5 bg-radix-mauve-black rounded-xs border p-4">
                <ArmColorLogo className="h-8 w-8" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="to-radix-mauve2 bg-linear-to-b from-transparent py-32">
        <div className="font-display px-4 pb-8 text-center text-5xl font-bold tracking-tight sm:px-0">
          Faster builds for everyone
        </div>

        <div className="mb-16 flex justify-center px-4 text-center">
          <Link
            to="/start"
            className="border-radix-grass7 bg-radix-grass3 text-radix-grass11 hover:border-radix-grass8 flex items-center gap-2 rounded-lg border px-4 py-3 text-sm leading-none font-bold whitespace-nowrap"
          >
            <span>Start building</span>
            <ChevronRightIcon className="" />
          </Link>
        </div>

        <TweetGrid />
      </div>
    </div>
  )
}
