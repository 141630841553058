import {
  AppsmithLogo,
  GridRivalLogo,
  PostHogLogo,
  RWXLogo,
  SecodaLogo,
  SemgrepLogo,
  TicketSwapLogo,
  WistiaLogo,
} from '~/components/logos'

export function UsedByLogos() {
  return (
    <div className="text-radix-mauve12 mx-auto grid w-full max-w-5xl grid-cols-2 items-center justify-center text-center sm:grid-cols-4">
      <a
        className="border-radix-mauve3 bg-radix-mauve-black hover:border-l-radix-mauve3 hover:border-t-radix-mauve3 hover:bg-radix-mauve1 flex h-52 items-center justify-center border-t border-b border-l border-t-transparent border-l-transparent px-8 transition-colors duration-1000 hover:text-white hover:duration-100"
        href="https://posthog.com/blog/speeding-up-posthog-builds-with-depot?ref=depot.dev"
        target="_blank"
        rel="noreferrer"
      >
        <PostHogLogo className="h-6" />
        <span className="sr-only">PostHog Logo</span>
      </a>
      <a
        className="border-radix-mauve3 bg-radix-mauve-black hover:border-t-radix-mauve3 hover:bg-radix-mauve1 hidden h-52 items-center justify-center border-t border-b border-l border-t-transparent px-8 transition-colors duration-1000 hover:text-white hover:duration-100 sm:flex"
        href="https://semgrep.dev/?ref=depot.dev"
        target="_blank"
        rel="noreferrer"
      >
        <SemgrepLogo className="h-5" />
        <span className="sr-only">Semgrep Logo</span>
      </a>
      <a
        className="border-radix-mauve3 bg-radix-mauve-black hover:border-t-radix-mauve3 hover:bg-radix-mauve1 hidden h-52 items-center justify-center border-t border-b border-l border-t-transparent px-8 transition-colors duration-1000 hover:text-white hover:duration-100 sm:flex"
        href="https://www.appsmith.com/?ref=depot.dev"
        target="_blank"
        rel="noreferrer"
      >
        <AppsmithLogo className="h-[1.4rem]" />
        <span className="sr-only">Appsmith Logo</span>
      </a>
      <a
        className="border-radix-mauve3 bg-radix-mauve-black hover:border-r-radix-mauve3 hover:border-t-radix-mauve3 hover:bg-radix-mauve1 flex h-52 items-center justify-center border-t border-r border-b border-l border-t-transparent border-r-transparent px-8 transition-colors duration-1000 hover:text-white hover:duration-100"
        href="https://www.ticketswap.com/?ref=depot.dev"
        target="_blank"
        rel="noreferrer"
      >
        <TicketSwapLogo className="h-6" />
        <span className="sr-only">TicketSwap Logo</span>
      </a>

      <a
        className="border-radix-mauve3 bg-radix-mauve-black hover:border-b-radix-mauve3 hover:border-l-radix-mauve3 hover:bg-radix-mauve1 flex h-52 items-center justify-center border-b border-l border-b-transparent border-l-transparent px-8 transition-colors duration-1000 hover:text-white hover:duration-100"
        href="https://wistia.com/?ref=depot.dev"
        target="_blank"
        rel="noreferrer"
      >
        <WistiaLogo className="h-5" />
        <span className="sr-only">Wistia Logo</span>
      </a>
      <a
        className="border-radix-mauve3 bg-radix-mauve-black hover:border-b-radix-mauve3 hover:bg-radix-mauve1 hidden h-52 items-center justify-center border-b border-l border-b-transparent px-8 transition-colors duration-1000 hover:text-white hover:duration-100 sm:flex"
        href="https://www.rwx.com/?ref=depot.dev"
        target="_blank"
        rel="noreferrer"
      >
        <RWXLogo className="h-4" />
        <span className="sr-only">RWX Logo</span>
      </a>
      <a
        className="border-radix-mauve3 bg-radix-mauve-black hover:border-b-radix-mauve3 hover:bg-radix-mauve1 hidden h-52 items-center justify-center border-b border-l border-b-transparent px-8 transition-colors duration-1000 hover:text-white hover:duration-100 sm:flex"
        href="https://gridrival.com/?ref=depot.dev"
        target="_blank"
        rel="noreferrer"
      >
        <GridRivalLogo className="h-4" />
        <span className="sr-only">GridRival Logo</span>
      </a>
      <a
        className="border-radix-mauve3 bg-radix-mauve-black hover:border-b-radix-mauve3 hover:border-r-radix-mauve3 hover:bg-radix-mauve1 flex h-52 items-center justify-center border-r border-b border-l border-r-transparent border-b-transparent px-8 transition-colors duration-1000 hover:text-white hover:duration-100"
        href="https://www.secoda.co/?ref=depot.dev"
        target="_blank"
        rel="noreferrer"
      >
        <SecodaLogo className="h-6" />
        <span className="sr-only">Secoda Logo</span>
      </a>
    </div>
  )
}
